import React from 'react'
import { Outlet } from 'react-router-dom';
import Home from '../pages/Home';

export default function Root() {
  return (
    <div className={`bg-[url('./assets/bg.jpg')] bg-cover`}>
      <Home />
      <Outlet />
    </div>
  )
}
