import React from 'react'

export default function Home() {
          return (
                    <div className='flex flex-col justify-center items-center h-screen text-white px-2 md:px-0 text-center'>
                              <h1 className='text-xl md:text-2xl lg:text-3xl font-lemonMilk'>
                                        Domain address has been changed to
                              </h1>
                              <h1 className='text-xl md:text-2xl lg:text-3xl mt-2'>
                                        <i className='bx bx-down-arrow-alt'></i>
                              </h1>
                              <div className='flex flex-col md:flex-row gap-5 md:gap-3 mt-2'>
                                        <button onClick={() => window.location.href = 'https://toufiqhasankiron.com'} className='btn btn-sm md:btn-md hover:bg-white font-monospaceTypewriter hover:text-black bg-transparent border border-white duration-700 text-white'>This Address</button>
                              </div>
                    </div>
          )
}
