import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './Layouts/Root';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />
    },
  ]
);

export default function App() {
  useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    const disableRightClick = (e: any) => {
      if (e.button === 2) {
        e.preventDefault()
      } else if (e.button === 0) {
        e.preventDefault()
      }
    }
    document.addEventListener("contextmenu", disableRightClick, { passive: false })
    return () => {
      document.removeEventListener("contextmenu", disableRightClick)
    }
  }, [])

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
